
$(document).on('click', '.confirm-action', function (e) {
    var url = $(this).attr('href');
    e.preventDefault();
    swal({
        title: "Are you sure?",
        text: "If you delete it, you will loose any informations related to this item. Could be user, informations, etc..",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    }).then((willDelete) => {
        if (willDelete) {
            window.location = url;
        }
    });
});

$(document).ready(function () {
    var el = document.getElementById('sortable-row');

    if(el) {
        var sortable = Sortable.create(el, {
            handle: ".my-handle",
            onEnd: function (/**Event*/evt) {

                var positions = [];

                $('#sortable-row .item').each(function () {
                    positions.push($(this).attr('data-id'));
                });

                $.get($('#sortable-row').attr('data-url-sort'), {positions: positions.join('|')})
                    .done(function (data) {
                        M.toast({
                            html: 'Positions changed!'
                        })
                    });
            },

        });
    }

    $('.dropify').dropify();
});

$(document).on('focusout', '.traduction-input', function () {
    var form = $(this).parent();
    var url = form.attr('action');

    $.ajax({
        type: "POST",
        url: url,
        data: form.serialize(), // serializes the form's elements.
        success: function(data)
        {
            if(data.success) {
                M.toast({
                    html: 'Translation updated !'
                })
            } else {
                M.toast({
                    html: 'We cannot update this translation'
                })
            }
        }
    });
});
